<template>
    <div>
        <div>
            <p class="form-label">
                {{ $t('default_positioning_method') }}
            </p>

            <BaseMultiselect
                v-model="config.default_positioning_method"
                :options="positioningMethodOptions"
            />
        </div>

        <FormInput
            v-for="configKey in configKeys.slice(1)"
            :key="configKey"
            v-model.number="$v.config[configKey].$model"
            type="number"
            :label="$t(configKey)"
            :errors="
                $v.config[configKey].$dirty &&
                $v.config[configKey].between === false
                    ? [
                          $t('mustBeBetween', [
                              $v.config[configKey].$params.between.min,
                              $v.config[configKey].$params.between.max,
                          ]),
                      ]
                    : []
            "
        />
    </div>
</template>

<script>
import { between } from 'vuelidate/lib/validators'

import { httpHelper } from '@/utils'
import BaseMultiselect from './redesigned/BaseMultiselect'
import FormInput from './FormInput'

export default {
    name: 'EditConfigComtacTd1',
    components: {
        BaseMultiselect,
        FormInput,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            config: null,
            configInitial: null,
            configKeys: [
                'default_positioning_method',
                'auto_positioning_interval',
                // 'gps_fixes',
                // 'long_range_trigger',
                // 'mid_range_trigger',
                // 'ping_interval',
                // 'min_wifi_detections',
                // 'rejoin_trigger',
            ],
            positioningMethodOptions: [
                'gps-only',
                'wifi-only',
                'gps-or-wifi',
                'wifi-or-gps',
                'gps-and-wifi',
            ],
        }
    },
    computed: {
        isChanged() {
            return (
                this.config &&
                this.configKeys.some(
                    key => this.config[key] !== this.configInitial[key]
                )
            )
        },
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                const config = this.configKeys.reduce(
                    (acc, key) => ({
                        ...acc,
                        [key]: this.value[key],
                    }),
                    {}
                )
                this.configInitial = { ...config }
                this.config = { ...config }
            },
        },
    },
    methods: {
        isValid() {
            this.$v.$touch()
            return !this.$v.$invalid
        },
        save(tracker) {
            return httpHelper.patch(
                `/trackers/${tracker.id}/config`,
                this.config
            )
        },
    },
    validations() {
        return {
            ...(this.config && {
                config: {
                    auto_positioning_interval: {},
                    gps_fixes: {
                        between: between(5, 20),
                    },
                    long_range_trigger: {
                        between: between(0, 255),
                    },
                    mid_range_trigger: {
                        between: between(0, 255),
                    },
                    min_wifi_detections: {
                        between: between(1, 4),
                    },
                    ping_interval: {
                        between: between(15, 50000),
                    },
                    rejoin_trigger: {
                        between: between(0, 50000),
                    },
                },
            }),
        }
    },
}
</script>

<i18n>
{
    "en": {
        "auto_positioning_interval": "Positioning interval in seconds",
        "default_positioning_method": "Default Positioning Method",
        "gps_fixes": "GPS Fixes",
        "long_range_trigger": "Long Range Trigger",
        "mid_range_trigger": "Mid Range Trigger",
        "min_wifi_detections": "Min WiFi Detections",
        "mustBeBetween": "Must be between {0} and {1}",
        "ping_interval": "Ping Interval",
        "rejoin_trigger": "Rejoin Trigger"
    },
    "de": {
        "auto_positioning_interval": "Positionierungsintervall in Sekunden",
        "default_positioning_method": "Standard-Positionierungsmethode",
        "gps_fixes": "GPS Fixes",
        "long_range_trigger": "Long Range Trigger",
        "mid_range_trigger": "Mid Range Trigger",
        "min_wifi_detections": "Min WiFi Detections",
        "mustBeBetween": "Must be between {0} and {1}",
        "ping_interval": "Ping Interval",
        "rejoin_trigger": "Rejoin Trigger"
    },
    "fr": {
        "auto_positioning_interval": "Intervalle de positionnement en secondes",
        "default_positioning_method": "Méthode de positionnement par défaut",
        "gps_fixes": "GPS Fixes",
        "long_range_trigger": "Long Range Trigger",
        "mid_range_trigger": "Mid Range Trigger",
        "min_wifi_detections": "Min WiFi Detections",
        "mustBeBetween": "Doit être compris entre {0} et {1}",
        "ping_interval": "Ping Interval",
        "rejoin_trigger": "Rejoin Trigger"
    },
    "it": {
        "auto_positioning_interval": "Intervallo di posizionamento in secondi",
        "default_positioning_method": "Metodo di posizionamento predefinito",
        "gps_fixes": "GPS Fixes",
        "long_range_trigger": "Long Range Trigger",
        "mid_range_trigger": "Mid Range Trigger",
        "min_wifi_detections": "Min WiFi Detections",
        "mustBeBetween": "Deve essere compreso tra {0} e {1}",
        "ping_interval": "Ping Interval",
        "rejoin_trigger": "Rejoin Trigger"
    }
}
</i18n>
