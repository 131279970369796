<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M18.751,8.5,12,1.75,5.251,8.5H9v6.75a.75.75,0,0,0,.75.75h4.5a.75.75,0,0,0,.75-.75V8.5Z"
        />

        <path
            class="a"
            d="M23.251,17.5v3a1.5,1.5,0,0,1-1.5,1.5H2.251a1.5,1.5,0,0,1-1.5-1.5v-3"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'UploadIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
