<template>
    <VMultiselect
        v-model="selectedAddress"
        :options="addresses"
        track-by="id"
        label="place_name"
        :searchable="true"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="true"
        :placeholder="$t('typeToSearch')"
        :multiple="false"
        :allow-empty="false"
        :close-on-select="true"
        :hide-selected="false"
        :show-labels="false"
        @search-change="asyncFind"
        @input="$emit('input', selectedAddress)"
    >
        <template #option="{ option }">
            <div class="option">
                <span>{{ option.place_name }}</span>
            </div>
        </template>

        <template #noOptions>
            {{ $t('noOptions') }}
        </template>

        <template #noResult>
            {{ $t('noResult') }}
        </template>
    </VMultiselect>
</template>

<script>
import axios from 'axios'
import { debounce } from 'lodash'
import VMultiselect from 'vue-multiselect'

export default {
    name: 'AddressSearch',
    components: {
        VMultiselect,
    },
    props: {
        proximityLatitude: {
            type: [String, Number],
            default: null,
        },
        proximityLongitude: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            addresses: [],
            isLoading: false,
            selectedAddress: {},
        }
    },
    methods: {
        asyncFind: debounce(async function(query) {
            if (!query.length) {
                return
            }

            this.isLoading = true
            const { data } = await axios.get(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json`,
                {
                    params: {
                        access_token: process.env.VUE_APP_MAPBOX_TOKEN,
                        ...(this.proximityLatitude &&
                            this.proximityLongitude && {
                                proximity: `${this.proximityLongitude},${this.proximityLatitude}`,
                            }),
                    },
                }
            )
            this.addresses = data.features
            this.isLoading = false
        }, 400),
    },
}
</script>

<i18n>
{
    "en": {
        "noOptions": "Start to type to get address results",
        "noResult": "No results were found",
        "typeToSearch": "Type to search address"
    },
    "de": {
        "noOptions": "Starte zu tippen um Adressen anzuzeigen",
        "noResult": "Keine Resultate gefunden",
        "typeToSearch": "Hier nach Adresse suchen"
    },
    "fr": {
        "noOptions": "Commencez à taper pour obtenir des résultats d'adresse",
        "noResult": "Aucun résultat trouvé",
        "typeToSearch": "Tapez pour rechercher l'adresse"
    },
    "it": {
        "noOptions": "Inizia a digitare per ottenere i risultati dell'indirizzo",
        "noResult": "Non sono stati trovati risultati",
        "typeToSearch": "Digitare l'indirizzo da cercare"
    }
}
</i18n>
