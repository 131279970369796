<template>
    <div>
        <p class="form-label">
            {{ $t('gps_fix_interval') }}
        </p>

        <BaseMultiselect
            v-model="config.gps_fix_interval"
            :options="gpsFixIntervalOptions"
            :custom-label="formatDuration"
        />
    </div>
</template>

<script>
import { formatHelper, httpHelper } from '@/utils'

import BaseMultiselect from './redesigned/BaseMultiselect'

export default {
    name: 'EditConfigAlpscout',
    components: {
        BaseMultiselect,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            config: null,
            configInitial: null,
            configKeys: ['gps_fix_interval'],
            gpsFixIntervalOptions: [
                10 * 60,
                30 * 60,
                60 * 60,
                6 * 60 * 60,
                12 * 60 * 60,
            ],
        }
    },
    computed: {
        isChanged() {
            return (
                this.config &&
                this.configKeys.some(
                    key => this.config[key] !== this.configInitial[key]
                )
            )
        },
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                const config = this.configKeys.reduce(
                    (acc, key) => ({
                        ...acc,
                        [key]: this.value[key],
                    }),
                    {}
                )
                this.configInitial = { ...config }
                this.config = { ...config }
            },
        },
    },
    methods: {
        formatDuration(seconds) {
            return formatHelper.hoursAndMinutesDuration(seconds)
        },
        save(tracker) {
            return httpHelper.post('/lora/downlinks/alpscout/config/', {
                deveui: tracker.deveui,
                ...this.config,
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "gps_fix_interval": "GPS Fix Interval"
    },
    "de": {
        "gps_fix_interval": "GPS Fix Intervall"
    },
    "fr": {
        "gps_fix_interval": "GPS Intervalle fixe"
    },
    "it": {
        "gps_fix_interval": "Intervallo di GPS"
    }
}
</i18n>
